import { isClerkError } from "@/types/clerk";

export function errorMessage(error: unknown) {
  if (error instanceof Error) {
    return error.message;
  } else if (isClerkError(error)) {
    if (error.code === "form_password_incorrect") {
      return "Password is incorrect.";
    } else {
      return error.message;
    }
  } else {
    return "An unknown error occurred.";
  }
}
