"use client";

import * as React from "react";

import { cn } from "@/lib/utils";
import { useSignIn } from "@clerk/nextjs";
import Link from "next/link";
import { Skeleton } from "@/components/ui/skeleton";
import { UserLoginForm } from "./user-login-form";
import Image from "next/image";

export function UserLoginFormWrapper({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  const { isLoaded, signIn, setActive } = useSignIn();

  if (!isLoaded) {
    return (
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col place-items-center space-y-2 text-center">
            <Skeleton className="h-[30px] w-[140px] rounded-full" />
            <Skeleton className="h-[20px] w-[300px] rounded-full" />
          </div>
          <div className="flex flex-col place-items-center space-y-2 pt-2 text-center">
            <Skeleton className="h-[40px] w-[340px] rounded-full" />
            <Skeleton className="h-[40px] w-[340px] rounded-full" />
            <Skeleton className="h-[40px] w-[340px] rounded-full" />
          </div>
          <div className="flex flex-col place-items-center space-y-2 text-center">
            <Skeleton className="h-[20px] w-[240px] rounded-full" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="lg:p-8">
      <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
        <div className="flex flex-col justify-center space-y-2 text-center">
          <div className="flex justify-center">
            <Image
              src="/SnapNrack - All Blue - no tag.png"
              width={140}
              height={30}
              alt="SnapNrack"
              className="dark:hidden"
            />
            <Image
              src="/SnapNrack - White - no tag.png"
              width={140}
              height={30}
              alt="SnapNrack"
              className="hidden dark:block"
            />
          </div>
          <p className="text-sm text-muted-foreground">
            Welcome back! Enter your email and password below.
          </p>
        </div>
        <div className={cn("grid gap-6", className)} {...props}>
          {isLoaded && <UserLoginForm signIn={signIn} setActive={setActive} />}
        </div>
        <p className="space-x-2 px-8 text-center text-sm text-muted-foreground">
          <span>Forgot your password?</span>
          <Link
            href="/reset"
            className="underline underline-offset-4 hover:text-primary"
          >
            Reset password.
          </Link>
        </p>
      </div>
    </div>
  );
}
